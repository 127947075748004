import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../component/NavBar';

const HomePage = () => {
  const [games, setGames] = useState([
    { id: 1, name: 'Truth or Dare', path: '/truthdare' },
  ]);

  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-900 via-gray-500 to-gray-700 flex flex-col">
      <Navbar className="p-4"/>
      <div className="flex-grow flex justify-center items-center">
        <div className="grid grid-cols-1 gap-6 mt-24">
          {games.map((game) => (
            <button
              key={game.id}
              className="bg-gradient-to-r from-red-900 via-yellow-500 to-black hover:bg-blue-700 text-white text-2xl font-bold py-20 px-20 rounded-lg shadow-lg transition-all duration-300 transform hover:scale-105"
              onClick={() => navigate(game.path)}
            >
              {game.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
