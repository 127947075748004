const Navbar = () => {
  return (
    <div className="bg-gradient-to-r from-gray-900 via-gray-500 to-gray-700 px-4 py-4">
      <nav className="flex items-center justify-between bg-gradient-to-r from-blue-900 via-pink-800 to-gray-700 shadow-lg rounded-xl p-4 mx-auto w-full max-w-screen-3xl">
        <div className="text-2xl font-bold text-white">Shuffler</div>
        <div className="hidden md:flex space-x-6">
          {/* <a href="#about" className="text-lg font-medium text-white hover:text-gray-300">
            About
          </a> */}
          <a href="mailto:parikshitshaktawat.it@gmail.com" className="text-lg font-medium text-white hover:text-gray-300">
            Contact
          </a>
          {/* <a href="#about" className="text-lg font-medium text-white hover:text-gray-300">About</a>
          <a href="#contact" className="text-lg font-medium text-white hover:text-gray-300">Contact</a> */}
        </div>
        <div className="md:hidden">
          <button className="focus:outline-none">
            <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
      </nav>
    </div>
  );
};
export default Navbar;