import React, { useState } from 'react';
import '../index.css'; // Ensure to import your CSS file
import Navbar from '../component/NavBar';

const TruthDare = () => {
    // Default truths and dares categorized by difficulty
    const defaultTruths = {
        easy: [
            "What's your favorite hobby?", "What's the name of your best friend?", "What's your favorite movie?", "When was the last time you lied?",
            "When was the last time you cried?", "What's your biggest fear?", "What's your biggest fantasy?", "Who's the last person you searched on Instagram?",
            "What's the strangest thing you've ever eaten?", "What's your relationship dealbreaker?", "What's a secret you've never told anyone?",
            "Do you have a hidden talent?", "Who was your first celebrity crush?", "Have you ever cheated in an exam?",
            "If you were going to be on a reality TV show, which would it be?", "What's your biggest insecurity?", "What's the biggest mistake you've ever made?",
            "What's one thing you hate people knowing about you?", "What's the worst thing anyone's ever done to you?",
            "What's the best thing anyone's ever done for you?", "What's your worst habit?", "What's the biggest misconception about you?",
            "What's one thing you wish people knew about you?", "Why did your last relationship break down?",
            "What's the best piece of advice you've been given?", "What's the most you've spent on a night out?",
            "Have you ever returned or re-gifted a present?", "Do you have a favourite sibling?",
            "What have you purchased that's been the biggest waste of money?", "How would you rate your looks on a scale of 1 to 10?",
            "What celebrity do you look up to?", "If you could be one celebrity for a day, who would it be?",
            "If you could do any job in the world, what would it be?", "What's your dream life?",
            "What do you value the most - money, fame, success, friends, family, etc?", "What’s your biggest red flag?",
            "If you had to marry someone in this room, who would it be and why?", "What’s one movie you’re embarrassed to admit you enjoy?",
            "What music artist do you secretly think is overrated?", "What’s your most controversial political opinion?", "Name something you can’t live without",
            "Where do you think you’ll be in five years time?", "What's on your 'On Repeat' Spotify playlist right now?",
            "How well do you think you’d do in a horror movie?", "How many stuffed animals do you own?", "Did you ever get in trouble at school? And if so what for?",
            "Do you have any phobias?", "Who was the last person you kissed?", "If you could marry any celebrity, who would it be and why?",
            "What is your love language?", "Who in your phone book would you least like to call right now?", "Who is your 'The One That Got Away'?",
            "Do you have a celebrity lookalike?", "What are your five most-watched films?",
        ],

        moderate: [
            "What's a secret you've never told anyone?",
            "Who is your celebrity crush?",
            "What's a habit you're trying to break?",
            "Have you ever lied to someone in this room?",
            "How often do you wash your sheets?",
            "What’s the most embarrassing problem you’ve gone to the doctor for?",
            "What is your biggest regret in life?",
            "What is the biggest secret you’re hiding from your parents?",
            "What movie is your guilty pleasure?",
            "What objectively terrible song do you love to listen to?",
            "What is the most expensive thing you’ve ever bought?",
            "If you had to marry one person in this room on the spot, who would you choose?",
            "Would you ever get plastic surgery?",
            "If you were stranded on a desert island with someone in this room, who would you choose?",
            "What’s the longest time you’ve gone without showering?",
            "Do you believe in ghosts?",
            "Have you ever been cheated on?",
            "What’s your biggest fear?",
            "What’s the strangest thing you’ve ever eaten?",
            "Who was your first celebrity crush?",
            "What’s your biggest insecurity?",
            "What’s your worst habit?",
            "Have you ever lied to get out of a bad date?",
            "Have you ever had an imaginary friend?",
            "What’s the worst thing you’ve ever said to anyone?",
            "What’s the biggest misconception about you?",
            "What’s the best piece of advice you’ve been given?",
            "If you could do any job in the world, what would it be?",
            "What’s your hidden talent?",
            "Do you still have feelings for any of your exes?",
            "Do you believe in aliens?",
            "What’s your worst fashion moment?",
            "Would you date your high school crush today?",
            "What’s your most bizarre nickname?",
            "What’s your biggest red flag?",
            "Why did your last relationship come to an end?",
            "Have you ever had a holiday fling?",
            "What is the biggest lie you’ve ever told?",
            "What do you want written on your tombstone?",
            "What’s one thing you only do when you’re alone?",
            "What is your favorite book of all time?",
            "What’s the worst date you’ve ever been on?",
            "What’s your biggest wish in life?",
            "What’s something that overwhelms you?",
            "What’s something you would do if you knew there were no consequences?",
            "Have you ever broken the law?",
            "What’s the strangest dream you’ve had?",
            "Have you ever re-worn dirty socks?",
            "What’s the strangest rumor you’ve heard about yourself?",
            "What’s the weirdest lie you’ve ever told?",
            "If you could choose a new name, what would you pick?",
            "Have you ever dined and dashed?",
            "What’s the last thing you Googled?",
            "What’s your favorite possession?",
            "Have you ever ghosted a friend?",
            "What’s your strangest dealbreaker?",
            "Where’s one place you’re dying to visit?",
            "What’s one thing in your life you wish you could change?",
            "Would you date someone shorter than you?",
            "Who are you most jealous of?",
            "Have you ever lied to your boss?",
            "What was the greatest day of your life?",
            "What app do you spend the most time on?",
            "What’s the best date you’ve been on?",
            "If you could only hear one song for the rest of your life, what would it be?",
            "Who in this group would you want to swap lives with for a week?",
            "How long was your longest relationship?",
            "What word do you hate the most?",
            "When was the last time you lied?",
            "What’s your biggest fantasy?",
            "What’s the biggest mistake you’ve ever made?",
            "What’s the one big ticket item you can’t live without?",
            "Who annoys you most in your life?",
            "Have you ever had a fling?",
            "What secretly scares you?",
        ],
        hard: [
            "What's the most embarrassing thing you've done?", "What's your biggest regret?", "What's the wildest thing you've done under the influence?",
            "Describe your most intimate experience.", "Share an 18+ fantasy.", "What was the last lie that you told?", "What was the last item you shoplifted and when?",
            "What adolescent fantasy do you still jerk or fiddle to?", "Explain when someone the opposite of your sexual orientation has made a pass at you. If never, make pass at player opposite of your sexual orientation.",
            "Group choice.", "Have you ever fantasized about a disney character? which one?", "Have you ever flashed anyone? if no, flash now.",
            "If your lover transformed into an animal and the only way to restore them to their human form was to mate with them, which animal would you prefer?",
            "Describe the sexiest clothes you ever wore and why?", "Group choice.", "What's the worst thing about your gender?",
            "Story of the first time you did your hardest drug?", "Have you ever been photographed or drawn nude? if not, get drawn nude.",
            "⁠Sluttiest thing you've ever done? (Sarah is banned from this question)", "Group Choice.", "Have you ever thought of suicide? How would you do it?",
            "If you had to kill one person in the room, who and why?", "What's your worst fear?", "What's the worst thing you've seen someone doing?",
            "What's the worst thing someone has caught you doing?", "Have you ever cried directly before/during/after sex? Explain. If no, cry now.", "Group Choice.",
            "Has anything foreign been in your ass? if not, put icecube down pants, let melt.", "When was the last time you faked an orgasm? did anyone buy it?",
            "Group Choice.", "What's the strangest thing you've masturbated to or with?", "Most embarrassing memory?",
        ],
    };

    const defaultDares = {
        easy: [
            "Do a silly dance.",
            "Speak in an accent for the next 10 minutes.",
            "Draw a funny picture.",
            "Sing a silly song out loud",
            "Dance in your seat for 30 seconds",
            "Make animal sounds for 1 minute",
            "Wear a funny hat for the next round",
            "Give a player a compliment",
            "Share a funny joke",
            "Do 10 jumping jacks",
            "Make a silly face and hold it for 10 seconds",
            "Talk with a funny accent for 1 round",
            "Share your favorite childhood memory",
            "Eat a snack with your non-dominant hand",
            "Play air guitar for 30 seconds",
            "Draw a mustache on your face",
            "Give another player a high-five",
            "Tell a funny story",
            "Hop on one foot for 10 seconds",
            "Make a funny animal noise every time you speak",
            "Show off your best dance move",
            "Sing a song with a mouthful of water",
            "Play rock-paper-scissors with another player",
            "Wear your socks on your hands",
            "Do a funny impression",
            "Share your favorite emoji",
            "Take a selfie with a silly expression",
            "Play hide-and-seek in the room",
            "Jump up and down 5 times",
            "Share a funny meme",
            "Give another player a temporary nickname",
            "Make a silly prediction",
            "Do a funny task with your eyes closed",
            "Play a game of Simon Says",
            "Share your favorite hobby",
            "Draw a picture with your feet",
            "Play a round of charades",
            "Share a funny quote",
            "Wear a funny wig",
            "Play a game of Would You Rather",
            "Make a silly bet",
            "Share your favorite food",
            "Play a round of Two Truths and a Lie",
            "Give another player a funny challenge",
            "Share your favorite movie quote",
            "Play a game of Telephone",
            "Make a silly resolution",
            "Share your favorite song",
            "Play a round of Hot Potato",
            "Draw a funny comic strip",
            "Play a game of Truth or Dare",
            "Share your favorite book",
            "Make a silly wish",
            "Play a round of Word Chain",
            "Share your favorite TV show",
            "Play a game of Scattergories",
            "Make a silly rule for the game",
            "Share your favorite sport",
            "Play a round of Pictionary",
        ],
        moderate: [
            "Do 20 push-ups.",
            "Post a silly photo on social media.",
            "Sing a song loudly.",
            "Ask a stranger to rate you out of ten",
            "Eat a teaspoon of hot sauce",
            "Show us everything inside your purse or bag",
            "Go outside and sing the national anthem at top volume",
            "Text your ex ‘happy birthday’",
            "Speak in a British accent for the rest of the day",
            "Text your crush",
            "Let the group pick your next karaoke song",
            "Switch clothes with another player for the rest of the game",
            "Read the last text message you sent out loud",
            "Share someone’s secret without divulging their name",
            "Narrate the game for the next round",
            "Let another player post any photo they want to your Instagram story",
            "Eat a surprise treat with your eyes closed",
            "Do a sock puppet show",
            "Post a photo of a hand with an engagement ring on Instagram",
            "Do and post a TikTok dance of the group’s choosing",
            "Talk in an accent for one round",
            "Shower with your clothes on",
            "Do 30 burpees",
            "Have a deep conversation with an item of furniture",
            "Give a kissing demonstration with a pillow",
            "Eat part of a banana peel",
            "Wear your shoes on your hands",
            "Give each player life advice you think they need",
            "Act like a baby",
            "Text someone lyrics to a song line by line",
            "Paint your nails using your teeth",
            "Take off your socks with your teeth",
            "Move in slow motion",
            "Spin in a circle ten times then walk to the other room",
            "Flirt badly with another player for one round",
            "Do a dramatic catwalk",
            "Do your best impersonation of a pirate",
            "Read aloud the most intimate text you’ve sent recently",
            "Avoid saying ‘like’ for the rest of the game",
            "Pretend to be a dog",
            "Bite directly into a lemon",
            "Read the last page of a book you haven’t finished yet",
            "Admit who was the one who got away",
            "Give one other player a back massage",
            "Lick a bar of soap",
            "Eat a snack without using your hands",
            "Sing all your responses",
            "Share your most scandalous hot take",
            "Describe the plot of your favorite movie as ridiculously as you can",
            "Bite directly into a scoop of ice cream",
            "Do the Macarena without music",
            "Write a poem dedicated to the other players",
            "Give yourself a finger mustache",
            "Let someone in the room dress you for your next big event",
            "Keep your hands still behind your back",
            "Put on multiple pairs of pants at one time",
            "Eat a leaf",
            "Do a magic trick",
            "Tell a lie at some point during the game",
            "Dress as a character of the group’s choosing",
            "Act out a commercial for a product chosen by the other players",
            "Eat five spoonfuls of a condiment of your choice",
            "Show the most embarrassing photo on your phone",
            "Do 50 push-ups",
            "Do your best sexy crawl",
            "Eat a raw piece of garlic",
            "Let the group look through your Instagram DMs",
            "Try and make yourself cry in front of the group",
            "Go outside and do your best wolf howl at the moon",
            "Plank for five whole minutes",
            "Eat a whole block of cheese",
            "Show us your screen time report",
            "Eat a raw onion like an apple",
            "Read out the last 10 things on your search history",
            "Do an impression of another player until your next turn",
            "Walk next door with a measuring cup and ask for a cup of sugar",
            "Yell out the first word that comes to your mind",
            "Insult everyone in the room",
            "Let another player style your hair and leave it that way for the rest of the game",
            "Do a freestyle rap about the other players for one minute",
            "Show the weirdest item you have in your bag",
            "Tell the most embarrassing that's ever happened to you",
            "Smell another player’s armpit",
            "Play air guitar for two minutes straight",
            "Tell each player who you think their celebrity look alike is",
            "Let the group decide your outfit for the night",
            "Try to drink a glass of water without using your hands",
            "Eat a raw egg",
            "Try to lick your elbow",
            "Burp the alphabet",
            "Let another player draw a tattoo on you in permanent marker",
            "Attempt to juggle two or three items of the asker’s choosing",
            "Serenade the person to your right",
            "Keep your eyes closed until it’s your turn again",
            "Sit in the corner of the room without speaking to anyone for the next 10 minutes",
            "Try to put your whole fist in your mouth",
            "Put your clothing on backwards for the rest of the evening",
        ],
        hard: [
            "Perform a seductive dance.",
            "Share a provocative secret.",
            "Send a suggestive text to someone.",
            "Carry player to your left around the room once",
            "Kiss player to your right",
            "Kiss player to your left",
            "Kiss player to your opposite",
            "Group choice",
            "Lick player's ear to your left",
            "Flash someone out the window",
            "Let player to your right text anyone in your phone once",
            "Spend next round on the player's lap across from you",
            "Have another player tie your hands together for next 3 rounds",
            "Let someone tattoo your thigh",
            "Take off either your shirt or your pants",
            "Person opposite you gives you a wedgie",
            "Stuff mouth with flour and try to whistle",
            "Swallow teaspoon of cinnamon",
            "Group choice",
            "Take a shot of least favorite liquor",
            "Head stand until next turn, if you fail take shot",
            "Invisible Chair sit against wall, if you fail take shot",
            "Let someone draw a mustache or unibrow on you",
            "All players pour 1.5 oz of their drink into player's cup, player must drink",
            "Group choice",
            "Demonstrate with player to your right your favorite sex position",
            "Run to back fence in underwear",
            "Blindfolded, identify another player by grabbing ass",
            "Group Choice",
            "Every player slaps your ass with yardstick",
            "Change profile picture to duckface, no explanation, must remain for 24 hours",
            "Put an ice cube in your underwear for one minute.",

            "Perform a sexy belly dance for your partner.",
            "Blindfold your partner and guide them around your body using only touch.",
            "Undress your partner using only your teeth.",
            "Clean the house naked.",
            "Go about your normal day but with no underwear on.",
            "Give your partner a massage, blindfolded.",
            "Remove your partner’s underwear using only your feet.",
            "Use each other as a human plate and put whipped cream, chocolate sauce, whatever takes your fancy!",
            "Share a fantasy that your loved one’s never heard before.",
            "Handcuff your partner and treat them to their favorite turn-ons.",
            "Read an erotic bedtime story out loud.",
            "Set up a nude photo shoot and capture your favorite poses.",
            "Go skinny dipping in your local river or lake.",
            "Sext your partner during work.",
            "Touch yourselves while your partner watches.",
            "Give your partner a lap dance.",
            "Pick one part of your body and have your partner focus all their attention there.",
            "Choose a place you’ve never had sex and take your partner there.",
            "Draw a picture with whipped cream on your partner’s body. ...Then lick it off.",
            "Try a new pick-up line on your partner.",
            "Re-enact an X-rated version of your first kiss.",
            "Choose your fantasy orgy guests from your partner’s contact list.",
            "Play the rest of the game naked.",
            "Feed your partner using only your mouth.",
            "Give your significant other a full-body massage.",
            "Act out the first time you slept together.",
            "Have sex in a new part of the house for the first time.",
            "Kiss your partner passionately, like the climax of a movie.",
            "Perform a sexy pole dance with a broom or a mop.",
            "Use body paint to turn each other into a work of art.",
            "Twerk to the sexiest song you can think of.",
            "Confess your kinky guilty pleasure and try it together.",
            "Let your partner dress you up, then direct you in a striptease.",
            "Challenge your loved one to a sexy pillow fight.",
            "Spell out what you want to do to your partner using only emojis.",
            "Pretend you work at a phone sex line and have your partner call in.",
            "Blindfold yourself and guess which part of your partner’s body you’re touching.",
            "Play with melted wax (but be careful!)",
            "Hide chocolate or candy in your clothes and have your significant other find it.",
            "Send a sexy selfie when they least expect it.",
            "Balance an ice cube on your belly button for as long as you can bear it.",
            "Show your partner the last X-rated clip you watched, ...And describe why it turned you on.",
            "Put on your significant other’s underwear and strut your stuff on the catwalk.",
            "Shave your partner’s body hair.",
            "Pretend to give oral sex to the nearest object you see.",
            "Try tantric sex.",
        ],
    };

    // State for truths, dares, current prompt, and selected difficulty
    const [truths, setTruths] = useState(defaultTruths.easy);
    const [dares, setDares] = useState(defaultDares.easy);
    const [currentPrompt, setCurrentPrompt] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [newTruths, setNewTruths] = useState('');
    const [newDares, setNewDares] = useState('');
    const [clearDefaults, setClearDefaults] = useState(false);
    const [difficulty, setDifficulty] = useState('easy');
    const [editing, setEditing] = useState('truths'); // Indicates which type is being edited
    const [animationClass, setAnimationClass] = useState(''); // For background animation

    // Shuffle functions
    const handleTruth = () => {
        const randomTruth = truths[Math.floor(Math.random() * truths.length)];
        setCurrentPrompt(randomTruth);
        triggerAnimation();
    };

    const handleDare = () => {
        const randomDare = dares[Math.floor(Math.random() * dares.length)];
        setCurrentPrompt(randomDare);
        triggerAnimation();
    };

    // Handle difficulty change
    const handleDifficultyChange = (level) => {
        setDifficulty(level);
        setTruths(defaultTruths[level]);
        setDares(defaultDares[level]);
        setCurrentPrompt("");
    };

    // Handle background animation
    const triggerAnimation = () => {
        setAnimationClass(`bg-${difficulty}-animation`);
        setTimeout(() => {
            setAnimationClass(''); // Reset after animation
        }, 1000); // Adjust duration as per your animation
    };


    // Handle editing modal
    const openEditModal = (type) => {
        setEditing(type);
        setIsEditing(true);
    };

    const closeEditModal = () => setIsEditing(false);

    // Handle adding new truths and dares
    const handleAddTruths = () => {
        const newTruthArray = newTruths.split(',').map(truth => truth.trim()).filter(truth => truth);
        if (clearDefaults) {
            setTruths(newTruthArray);
        } else {
            setTruths([...truths, ...newTruthArray]);
        }
        setNewTruths('');
    };

    const handleAddDares = () => {
        const newDareArray = newDares.split(',').map(dare => dare.trim()).filter(dare => dare);
        if (clearDefaults) {
            setDares(newDareArray);
        } else {
            setDares([...dares, ...newDareArray]);
        }
        setNewDares('');
    };

    return (
        <>
          <Navbar />
          <div className={`min-h-screen grid grid-rows-layout bg-gradient-to-r from-gray-900 via-gray-500 to-gray-700 ${animationClass}`}>
            
            {/* Difficulty Selector (25%) */}
            <div className="row-span-1 flex flex-col items-center justify-center mt-6">
              <h2 className="text-white text-3xl mb-20">Select Difficulty Level</h2>
              <div className="flex space-x-6 font-bold">
                <button
                  onClick={() => handleDifficultyChange('easy')}
                  className={`py-4 px-6 rounded-lg text-white transition-all ${difficulty === 'easy' ? 'bg-green-600' : 'bg-green-400'} hover:scale-110`}
                >
                  Easy
                </button>
                <button
                  onClick={() => handleDifficultyChange('moderate')}
                  className={`py-4 px-6 rounded-lg text-white transition-all ${difficulty === 'moderate' ? 'bg-yellow-600' : 'bg-yellow-400'} hover:scale-110`}
                >
                  Moderate
                </button>
                <button
                  onClick={() => handleDifficultyChange('hard')}
                  className={`py-4 px-6 rounded-lg text-white transition-all ${difficulty === 'hard' ? 'bg-red-600' : 'bg-red-400'} hover:scale-110`}
                >
                  Hard
                </button>
              </div>
            </div>
      
            {/* Main Content (Game - 50%) */}
            <div className="row-span-2 flex flex-col space-y-6 items-center ">
              {/* Big prompt window */}
              <div className="bg-white text-black text-2xl p-12 rounded-lg shadow-lg w-full max-w-lg text-center mb-12 mt-8">
                {currentPrompt ? currentPrompt : 'Press Truth or Dare to begin!'}
              </div>
      
              {/* Truth and Dare buttons */}
              <div className="flex space-x-6 mb-12">
                <button
                  onClick={handleTruth}
                  className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-6 px-16 rounded-lg shadow-lg text-xl transition-all transform hover:scale-105 active:bg-blue-500"
                >
                  Truth
                </button>
      
                <button
                  onClick={handleDare}
                  className="bg-red-600 hover:bg-red-800 text-white font-bold py-6 px-16 rounded-lg shadow-lg text-xl transition-all transform hover:scale-105 active:bg-red-500"
                >
                  Dare
                </button>
              </div>
      
              {/* Edit Buttons */}
              <div className="flex space-x-6 mb-16">
                <button
                  onClick={() => openEditModal('truths')}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-lg text-lg"
                >
                  Edit Truths
                </button>
                <button
                  onClick={() => openEditModal('dares')}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg shadow-lg text-lg"
                >
                  Edit Dares
                </button>
              </div>
            </div>
      
            {/* Modal for Editing Truths and Dares */}
            {isEditing && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                  <h2 className="text-2xl font-bold mb-4">Edit {editing.charAt(0).toUpperCase() + editing.slice(1)}</h2>
                  {editing === 'truths' && (
                    <div className="mb-4">
                      <h3 className="text-xl font-semibold mb-2">Add New Truths</h3>
                      <textarea
                        value={newTruths}
                        onChange={(e) => setNewTruths(e.target.value)}
                        placeholder="Enter new truths separated by commas"
                        rows="4"
                        className="w-full p-2 border rounded-lg mb-2"
                      />
                      <button
                        onClick={handleAddTruths}
                        className="bg-blue-600 text-white py-2 px-4 rounded-lg"
                      >
                        Add Truths
                      </button>
                    </div>
                  )}
                  {editing === 'dares' && (
                    <div className="mb-4">
                      <h3 className="text-xl font-semibold mb-2">Add New Dares</h3>
                      <textarea
                        value={newDares}
                        onChange={(e) => setNewDares(e.target.value)}
                        placeholder="Enter new dares separated by commas"
                        rows="4"
                        className="w-full p-2 border rounded-lg mb-2"
                      />
                      <button
                        onClick={handleAddDares}
                        className="bg-red-600 text-white py-2 px-4 rounded-lg"
                      >
                        Add Dares
                      </button>
                    </div>
                  )}
                  <div className="mb-4">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={clearDefaults}
                        onChange={(e) => setClearDefaults(e.target.checked)}
                        className="mr-2"
                      />
                      <span>Clear default prompts</span>
                    </label>
                  </div>
                  <button
                    onClick={closeEditModal}
                    className="mt-4 bg-gray-600 hover:bg-gray-800 text-white py-2 px-4 rounded-lg"
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      );
      
      

};

export default TruthDare;
